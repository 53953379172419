/** @format */

import React, { Fragment } from "react";
import ManageUser from "./ManageUser";

const Index = () => {
  return (
    <Fragment>
      <ManageUser/>
    </Fragment>
  );
};

export default Index;
