/** @format */

export const GET_PROFILE = "GET_PROFILE";
export const DELETE_PROFILE = "DELETE_PROFILE";
export const ADD_PROFILE = "ADD_PROFILE";
export const GET_ERRORS = "GET_ERRORS";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const CLEAR_PROFILES = "CLEAR_PROFILES";
export const MONTLY_REVENUE = "GET_MONTLY_REVENUE";
export const WEEKLY_REVENUE = "GET_WEEKLY_REVENUE";
export const GET_IS_WEEKLY = "GET_IS_WEEKLY";
export const UPDATE_IS_WEEKLY = "UPDATE_IS_WEEKLY";

export const CREATE_MEMBER_SUCCESS = "CREATE_MEMBER_SUCCESS";
export const CREATE_MEMBER_FAIL = "CREATE_MEMBER_FAIL";

export const GET_MEMBER_SUCCESS = "GET_MEMBER_SUCCESS";
export const GET_MEMBER_FAIL = "GET_MEMBER_FAIL";

export const GET_TIMEZONE_SUCCESS = "GET_TIMEZONE_SUCCESS";
export const GET_TIMEZONE_FAIL = "GET_TIMEZONE_FAIL";
export const GET_CURRENT_TIME_SCUSSESS = "GET_CURRENT_TIME_SCUSSESS";
export const GET_CURRENT_TIME_FAIL = "GET_CURRENT_TIME_FAIL";
// export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
// export const LOGIN_FAIL = 'LOGIN_FAIL';
// export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
// export const SIGNUP_FAIL = 'SIGNUP_FAIL';
// export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
// export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';
// export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
// export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
// export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
// export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
// export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
// export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
// export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL';
// export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS';
// export const GOOGLE_AUTH_SUCCESS = 'GOOGLE_AUTH_SUCCESS';
// export const GOOGLE_AUTH_FAIL = 'GOOGLE_AUTH_FAIL';
// export const FACEBOOK_AUTH_SUCCESS = 'FACEBOOK_AUTH_SUCCESS';
// export const FACEBOOK_AUTH_FAIL = 'FACEBOOK_AUTH_FAIL';
// export const LOGOUT = 'LOGOUT';

// export const PROFILE_CREATED_SUCCESS = 'PROFILE_CREATED_SUCCESS';
// export const PROFILE_CREATED_FAIL = 'PROFILE_CREATED_FAIL';

// export const PROFILE_LOADED_SUCCESS = 'PROFILE_LOADED_SUCCESS';
// export const PROFILE_LOADED_FAIL = 'PROFILE_LOADED_FAIL';

// export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
// export const PROFILE_UPDATE_FAIL = 'PROFILE_UPDATE_FAIL';
